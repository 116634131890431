<template>
  <div class="container">
    <div class="img"></div>
    <div class="btn" @click="copyQQ()">复制管理员QQ</div>
  </div>
</template>

<script>
export default {
  name: "403",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  watch: {},
  mounted() {},
  methods: {
    copyQQ() {
      // 12334962
      // let url = data;
      let oInput = document.createElement("input");
      oInput.value = '12334962';
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message.success("复制成功！");
      oInput.remove();
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 600px;
  .img {
    width: 500px;
    height: 400px;
    background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/common/403.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  .btn {
    width: 140px;
    height: 40px;
    background: linear-gradient(90deg, #65bfff, #4289ff);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 3s;
    -webkit-transition: all 3s;
    &:hover {
      background: linear-gradient(90deg, #6ebbf1, #2c6ed8);
    }
  }
}
</style>
